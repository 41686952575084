import { createContext, useContext, ReactNode } from 'react'
import {
  TUseCurrentUserRole,
  useCurrentUserRole,
} from 'common/hooks/use-current-user-role'

interface ICurrentUserRoleContext extends TUseCurrentUserRole {}

interface ICurrentUserRoleProps {
  children: ReactNode
}

const currentUserRoleContext = createContext({} as ICurrentUserRoleContext)

export const CurrentUserRoleContext = (props: ICurrentUserRoleProps) => {
  const userRoles = useCurrentUserRole()

  return (
    <currentUserRoleContext.Provider value={userRoles}>
      {props.children}
    </currentUserRoleContext.Provider>
  )
}

export const useCurrentUserRoleContext = () =>
  useContext(currentUserRoleContext)
