// It is a must to ask permission to user before displaying notification
export const askPermission = () => {
  // Check if the browser supports notifications
  if (!('Notification' in window)) {
    alert('This browser does not support desktop notification')
    return false
  }
  // Check whether notification permissions have already been granted;
  else if (Notification.permission === 'granted') {
    return true
  }
  // We need to ask the user for permission
  else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        return true
      } else return false
    })
  }
}

// Checks if the browser supports push notification and service worker
export const checkBrowserCompatibility = () => {
  // validate if service worker is supported in browser
  if (!('serviceWorker' in navigator)) {
    console.log("Service Worker isn't supported on this browser")
    return false
  }
  // validate if pushmanager is supported in browser
  else if (!('PushManager' in window)) {
    console.log("Push isn't supported on this browser, disable or hide UI")
    return false
  } else {
    return true
  }
}
