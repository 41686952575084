/* tslint:disable */
/* eslint-disable */
/**
 * Smart Central - Parent Application
 * Smart Central - Parent Application API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PostUpdatePassword200Response } from '../models';
// @ts-ignore
import { UserFinance } from '../models';
// @ts-ignore
import { UserInfo } from '../models';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user finance information
         * @param {number} srvId ID of service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFinance: async (srvId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'srvId' is not null or undefined
            assertParamExists('getUserFinance', 'srvId', srvId)
            const localVarPath = `/user/finance.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (srvId !== undefined) {
                localVarQueryParameter['srv_id'] = srvId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates user password
         * @param {string} existingPassword Existing password
         * @param {string} newPassword New password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatePassword: async (existingPassword: string, newPassword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'existingPassword' is not null or undefined
            assertParamExists('postUpdatePassword', 'existingPassword', existingPassword)
            // verify required parameter 'newPassword' is not null or undefined
            assertParamExists('postUpdatePassword', 'newPassword', newPassword)
            const localVarPath = `/user/update_password.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (existingPassword !== undefined) {
                localVarQueryParameter['existing_password'] = existingPassword;
            }

            if (newPassword !== undefined) {
                localVarQueryParameter['new_password'] = newPassword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get user finance information
         * @param {number} srvId ID of service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserFinance(srvId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFinance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserFinance(srvId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates user password
         * @param {string} existingPassword Existing password
         * @param {string} newPassword New password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUpdatePassword(existingPassword: string, newPassword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostUpdatePassword200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUpdatePassword(existingPassword, newPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Get user finance information
         * @param {number} srvId ID of service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFinance(srvId: number, options?: any): AxiosPromise<UserFinance> {
            return localVarFp.getUserFinance(srvId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any): AxiosPromise<UserInfo> {
            return localVarFp.getUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates user password
         * @param {string} existingPassword Existing password
         * @param {string} newPassword New password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatePassword(existingPassword: string, newPassword: string, options?: any): AxiosPromise<PostUpdatePassword200Response> {
            return localVarFp.postUpdatePassword(existingPassword, newPassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Get user finance information
     * @param {number} srvId ID of service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getUserFinance(srvId: number, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getUserFinance(srvId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getUserInfo(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates user password
     * @param {string} existingPassword Existing password
     * @param {string} newPassword New password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public postUpdatePassword(existingPassword: string, newPassword: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).postUpdatePassword(existingPassword, newPassword, options).then((request) => request(this.axios, this.basePath));
    }
}
