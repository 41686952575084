import { useMemo } from 'react'
import { useSession } from 'next-auth/react'
import { GraphQLClient } from 'graphql-request'

export const useGQLClient = () => {
  const { data: session, status: sessionStatus } = useSession()

  const accessToken = useMemo(() => {
    return session && sessionStatus === 'authenticated'
      ? (session.accessToken as string)
      : ''
  }, [session, sessionStatus])

  const gqlClient = useMemo(
    () =>
      new GraphQLClient(process.env.NEXT_PUBLIC_BASE_URL + `/graphql`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }),
    [accessToken]
  )

  return { gqlClient, isConfigReady: Boolean(accessToken) }
}
