import { createTheme } from '@mui/material/styles'

const COLOR_PRIMARY = '#0D47A1'
const COLOR_PRIMARY_LIGHT = '#388bff'
const COLOR_SECONDARY = '#E91E63'
const COLOR_BACKGROUND = '#F5F5F5'
const COLOR_SUCCESS = '#000000'
const COLOR_WARNING = '#000000'
const COLOR_INFO = '#000000'
const COLOR_ERROR = '#ff0000'
const COLOR_ERROR_LIGHT = '#D63921'

export const Default = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: COLOR_PRIMARY,
      light: COLOR_PRIMARY_LIGHT,
    },
    secondary: {
      main: COLOR_SECONDARY,
    },
    background: {
      default: COLOR_BACKGROUND,
    },
    success: {
      main: COLOR_SUCCESS,
    },
    error: {
      main: COLOR_ERROR,
      light: COLOR_ERROR_LIGHT
    },
    warning: {
      main: COLOR_WARNING,
    },
    info: {
      main: COLOR_INFO,
    },
  },
  typography: {
    h1: {
      color: COLOR_PRIMARY,
    },
    h2: {
      color: COLOR_PRIMARY,
    },
    h3: {
      color: COLOR_PRIMARY,
    },
    h4: {
      color: COLOR_PRIMARY,
    },
    h5: {
      color: COLOR_PRIMARY,
    },
    h6: {
      color: COLOR_PRIMARY,
    },
    body1: {
      fontFamily: `'Roboto'`,
    },
    button: {
      fontFamily: `'Roboto'`,
    },
    caption: {
      fontFamily: `'Roboto'`,
    },
    body2: {
      fontFamily: `'Roboto'`,
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        container: {
          backdropFilter: 'blur(5px)',
        },
      },
    },
  },
})
