import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
};

export type Absence = {
  __typename?: 'Absence';
  endDate: Scalars['String']['output'];
  finalFee?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  startDate: Scalars['String']['output'];
};

/** Autogenerated return type of ActionReport */
export type ActionReportPayload = {
  __typename?: 'ActionReportPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Activity = {
  __typename?: 'Activity';
  activityCategory?: Maybe<ActivityCategory>;
  createdAt: Scalars['String']['output'];
  dateFrom: Scalars['String']['output'];
  dateTo?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  every?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isEvent: Scalars['Boolean']['output'];
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  reports?: Maybe<Array<Report>>;
  style?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Tag>;
  timeFrom?: Maybe<Scalars['String']['output']>;
  timeTo?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userEmail: Scalars['String']['output'];
  userName: Scalars['String']['output'];
  weekdays?: Maybe<Array<Scalars['Int']['output']>>;
};

export type ActivityCategory = {
  __typename?: 'ActivityCategory';
  createdAt: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  serviceId: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type App = {
  __typename?: 'App';
  childDob?: Maybe<Scalars['String']['output']>;
  childFirstName?: Maybe<Scalars['String']['output']>;
  childLastName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  parentEmail?: Maybe<Scalars['String']['output']>;
  parentFirstName?: Maybe<Scalars['String']['output']>;
  parentLastName?: Maybe<Scalars['String']['output']>;
  plannedDays?: Maybe<Array<Scalars['String']['output']>>;
  plannedStartDate?: Maybe<Scalars['String']['output']>;
};

export type Booking = {
  __typename?: 'Booking';
  endDate: Scalars['String']['output'];
  enrolmentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  sessionCodeId: Scalars['ID']['output'];
  startDate: Scalars['String']['output'];
  style: Scalars['String']['output'];
};

export type Capture = {
  __typename?: 'Capture';
  caption?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  date: Scalars['String']['output'];
  errors?: Maybe<Array<Scalars['String']['output']>>;
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  mime?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Tag>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userEmail: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type CcsPaymentItem = {
  __typename?: 'CcsPaymentItem';
  amount?: Maybe<Scalars['Float']['output']>;
  clearingDocumentNumber?: Maybe<Scalars['String']['output']>;
  datePaid?: Maybe<Scalars['String']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  enrolmentId: Scalars['String']['output'];
  exempted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  mainTransactionCode?: Maybe<Scalars['String']['output']>;
  serviceId: Scalars['String']['output'];
  sessionReportStartDate: Scalars['String']['output'];
  subTransactionCode?: Maybe<Scalars['String']['output']>;
};

export type CcsPerson = {
  __typename?: 'CcsPerson';
  ccsUid: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  usualEducatorAddressLine1?: Maybe<Scalars['String']['output']>;
  usualEducatorAddressLine2?: Maybe<Scalars['String']['output']>;
  usualEducatorAddressPostCode?: Maybe<Scalars['String']['output']>;
  usualEducatorAddressState?: Maybe<Scalars['String']['output']>;
  usualEducatorAddressSuburb?: Maybe<Scalars['String']['output']>;
};

export type Child = {
  __typename?: 'Child';
  contacts?: Maybe<Array<Contact>>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  everything?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructions?: Maybe<Array<ChildInstruct>>;
  lastName: Scalars['String']['output'];
};

export type ChildInstruct = {
  __typename?: 'ChildInstruct';
  category: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type Comment = {
  __typename?: 'Comment';
  body: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isInternalEvaluation: Scalars['Boolean']['output'];
  objId: Scalars['ID']['output'];
  objType: Scalars['String']['output'];
  serviceId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  user: User;
};

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  obsPlatformAuthorised?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of CreateAbsence */
export type CreateAbsencePayload = {
  __typename?: 'CreateAbsencePayload';
  absence?: Maybe<Absence>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated return type of CreateActivityCategory */
export type CreateActivityCategoryPayload = {
  __typename?: 'CreateActivityCategoryPayload';
  activityCategory?: Maybe<ActivityCategory>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated return type of CreateCapture */
export type CreateCapturePayload = {
  __typename?: 'CreateCapturePayload';
  captures?: Maybe<Array<Capture>>;
};

/** Autogenerated return type of CreateComment */
export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated return type of CreateCustomTag */
export type CreateCustomTagPayload = {
  __typename?: 'CreateCustomTagPayload';
  customTag?: Maybe<CustomTag>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated return type of CreateExtraSession */
export type CreateExtraSessionPayload = {
  __typename?: 'CreateExtraSessionPayload';
  /** The created booking */
  booking?: Maybe<Booking>;
  /** Errors messages if any */
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated return type of CreateForm */
export type CreateFormPayload = {
  __typename?: 'CreateFormPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  form?: Maybe<Form>;
};

/** Autogenerated return type of CreateInvoice */
export type CreateInvoicePayload = {
  __typename?: 'CreateInvoicePayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  invoice?: Maybe<Invoice>;
};

/** Autogenerated return type of CreateNote */
export type CreateNotePayload = {
  __typename?: 'CreateNotePayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  note?: Maybe<Note>;
};

/** Autogenerated return type of CreateNotificationView */
export type CreateNotificationViewPayload = {
  __typename?: 'CreateNotificationViewPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated return type of CreateSessionSale */
export type CreateSessionSalePayload = {
  __typename?: 'CreateSessionSalePayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  session?: Maybe<ServiceSession>;
};

/** Autogenerated return type of CreateSmartForm */
export type CreateSmartFormPayload = {
  __typename?: 'CreateSmartFormPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  form?: Maybe<SmartForm>;
};

/** Autogenerated return type of CreateTemplate */
export type CreateTemplatePayload = {
  __typename?: 'CreateTemplatePayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  template?: Maybe<Report>;
};

export type CustomTag = {
  __typename?: 'CustomTag';
  colour?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Autogenerated return type of DeleteActivityCategory */
export type DeleteActivityCategoryPayload = {
  __typename?: 'DeleteActivityCategoryPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated return type of DeleteComment */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated return type of DeleteCustomTag */
export type DeleteCustomTagPayload = {
  __typename?: 'DeleteCustomTagPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of DeleteForm */
export type DeleteFormPayload = {
  __typename?: 'DeleteFormPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated return type of DeleteInvoice */
export type DeleteInvoicePayload = {
  __typename?: 'DeleteInvoicePayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of DeleteKindyhubObject */
export type DeleteKindyhubObjectPayload = {
  __typename?: 'DeleteKindyhubObjectPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of DeleteSessionSale */
export type DeleteSessionSalePayload = {
  __typename?: 'DeleteSessionSalePayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated return type of DeleteSmartForm */
export type DeleteSmartFormPayload = {
  __typename?: 'DeleteSmartFormPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  form?: Maybe<SmartForm>;
};

export type EBooking = {
  __typename?: 'EBooking';
  day: Scalars['String']['output'];
  room?: Maybe<Scalars['String']['output']>;
  roomId?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated return type of EditComment */
export type EditCommentPayload = {
  __typename?: 'EditCommentPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated return type of EditCustomTag */
export type EditCustomTagPayload = {
  __typename?: 'EditCustomTagPayload';
  customTag?: Maybe<CustomTag>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

export type Enrolment = {
  __typename?: 'Enrolment';
  bookings?: Maybe<Array<EBooking>>;
  ccsMaxHrs?: Maybe<Scalars['String']['output']>;
  ccsPercentage?: Maybe<Scalars['String']['output']>;
  ccsWithholding?: Maybe<Scalars['String']['output']>;
  child: Child;
  childId: Scalars['ID']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  entitlement?: Maybe<Scalars['String']['output']>;
  entitlementHistory?: Maybe<Array<EntitlementChange>>;
  id: Scalars['ID']['output'];
  noObservation?: Maybe<Scalars['Boolean']['output']>;
  parent1: Parent;
  parent2?: Maybe<ParentTwo>;
  parentId: Scalars['ID']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type EntitlementChange = {
  __typename?: 'EntitlementChange';
  createdAt: Scalars['String']['output'];
  details: Scalars['String']['output'];
};

export type Form = {
  __typename?: 'Form';
  answers?: Maybe<Scalars['String']['output']>;
  config?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  fileURL: Scalars['String']['output'];
  formDate?: Maybe<Scalars['String']['output']>;
  gridMode?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  submittedBy: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type FormConfiguration = {
  __typename?: 'FormConfiguration';
  config?: Maybe<Scalars['String']['output']>;
  gridMode?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  layout: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Invoice = {
  __typename?: 'Invoice';
  dateFrom: Scalars['String']['output'];
  dateTo: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  parentId: Scalars['ID']['output'];
  sent: Scalars['Boolean']['output'];
  token: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type LearningOutcome = {
  __typename?: 'LearningOutcome';
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  system: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  actionReport?: Maybe<ActionReportPayload>;
  createAbsence?: Maybe<CreateAbsencePayload>;
  createActivityCategory?: Maybe<CreateActivityCategoryPayload>;
  createCapture?: Maybe<CreateCapturePayload>;
  /** Creates a new comment */
  createComment?: Maybe<CreateCommentPayload>;
  /** Create a new custom tag */
  createCustomTag?: Maybe<CreateCustomTagPayload>;
  createExtraSession?: Maybe<CreateExtraSessionPayload>;
  createForm?: Maybe<CreateFormPayload>;
  createInvoice?: Maybe<CreateInvoicePayload>;
  createNote?: Maybe<CreateNotePayload>;
  /** Creates a new notification view */
  createNotificationViews?: Maybe<CreateNotificationViewPayload>;
  createSession?: Maybe<CreateSessionSalePayload>;
  /** Creates a new form */
  createSmartForm?: Maybe<CreateSmartFormPayload>;
  createTemplate?: Maybe<CreateTemplatePayload>;
  deleteActivityCategory?: Maybe<DeleteActivityCategoryPayload>;
  /** Deletes a comment */
  deleteComment?: Maybe<DeleteCommentPayload>;
  /** Deletes a custom tag */
  deleteCustomTag?: Maybe<DeleteCustomTagPayload>;
  deleteForm?: Maybe<DeleteFormPayload>;
  deleteInvoice?: Maybe<DeleteInvoicePayload>;
  deleteKindyhubObject?: Maybe<DeleteKindyhubObjectPayload>;
  deleteSession?: Maybe<DeleteSessionSalePayload>;
  /** deletes an existing form */
  deleteSmartForm?: Maybe<DeleteSmartFormPayload>;
  /** Edits a comment */
  editComment?: Maybe<EditCommentPayload>;
  /** Edits a custom tag */
  editCustomTag?: Maybe<EditCustomTagPayload>;
  revertReport?: Maybe<RevertReportPayload>;
  saveActivity?: Maybe<SaveActivityPayload>;
  saveCapture?: Maybe<SaveCapturePayload>;
  saveReport?: Maybe<SaveReportPayload>;
  saveTags?: Maybe<SaveTagsPayload>;
  updateActivityCategory?: Maybe<UpdateActivityCategoryPayload>;
  /** Updates an existing form */
  updateSmartForm?: Maybe<UpdateSmartFormPayload>;
};


export type MutationActionReportArgs = {
  action: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};


export type MutationCreateAbsenceArgs = {
  bookingId: Scalars['String']['input'];
  date: Scalars['String']['input'];
  enrolmentIdSwappedTo?: InputMaybe<Scalars['String']['input']>;
  fee?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateActivityCategoryArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  serviceId: Scalars['ID']['input'];
};


export type MutationCreateCaptureArgs = {
  date: Scalars['String']['input'];
  fileData: Scalars['String']['input'];
  serviceId: Scalars['String']['input'];
};


export type MutationCreateCommentArgs = {
  body: Scalars['String']['input'];
  isInternalEvaluation?: InputMaybe<Scalars['Boolean']['input']>;
  objId: Scalars['ID']['input'];
  objType?: InputMaybe<Scalars['String']['input']>;
  serviceId: Scalars['ID']['input'];
};


export type MutationCreateCustomTagArgs = {
  colour?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  serviceId: Scalars['String']['input'];
};


export type MutationCreateExtraSessionArgs = {
  date: Scalars['String']['input'];
  enrolmentId: Scalars['ID']['input'];
  roomId: Scalars['ID']['input'];
};


export type MutationCreateFormArgs = {
  answers: Scalars['String']['input'];
  formDate?: InputMaybe<Scalars['String']['input']>;
  formSubmissionId?: InputMaybe<Scalars['ID']['input']>;
  serviceId: Scalars['ID']['input'];
  smartFormId: Scalars['ID']['input'];
};


export type MutationCreateInvoiceArgs = {
  dateFrom: Scalars['String']['input'];
  dateTo: Scalars['String']['input'];
  fdcEducatorId?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  parentId: Scalars['String']['input'];
  sendEmail: Scalars['Boolean']['input'];
  type: Scalars['String']['input'];
  weeksToProject?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateNoteArgs = {
  content: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
  pubToKiosk: Scalars['Boolean']['input'];
  pubToParent: Scalars['Boolean']['input'];
};


export type MutationCreateNotificationViewsArgs = {
  notificationId: Scalars['ID']['input'];
};


export type MutationCreateSessionArgs = {
  enrolmentId: Scalars['String']['input'];
  productId: Scalars['String']['input'];
};


export type MutationCreateSmartFormArgs = {
  config?: InputMaybe<Scalars['JSON']['input']>;
  gridMode: Scalars['Boolean']['input'];
  layout: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  serviceId: Scalars['ID']['input'];
};


export type MutationCreateTemplateArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteActivityCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCustomTagArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFormArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInvoiceArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteKindyhubObjectArgs = {
  objId: Scalars['Int']['input'];
  objType: Scalars['String']['input'];
};


export type MutationDeleteSessionArgs = {
  enrolmentId: Scalars['String']['input'];
  productId: Scalars['String']['input'];
};


export type MutationDeleteSmartFormArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEditCommentArgs = {
  body: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isInternalEvaluation?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationEditCustomTagArgs = {
  colour?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationRevertReportArgs = {
  id: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
};


export type MutationSaveActivityArgs = {
  activityCategoryId?: InputMaybe<Scalars['ID']['input']>;
  activityId?: InputMaybe<Scalars['ID']['input']>;
  dateFrom: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  every?: InputMaybe<Scalars['Int']['input']>;
  isEvent?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  serviceId: Scalars['String']['input'];
  style?: InputMaybe<Scalars['String']['input']>;
  timeFrom?: InputMaybe<Scalars['String']['input']>;
  timeTo?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  weekdays: Array<Scalars['Int']['input']>;
};


export type MutationSaveCaptureArgs = {
  caption?: InputMaybe<Scalars['String']['input']>;
  captureId: Scalars['ID']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  serviceId: Scalars['String']['input'];
};


export type MutationSaveReportArgs = {
  content?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  reportId?: InputMaybe<Scalars['ID']['input']>;
  serviceId: Scalars['String']['input'];
  smartFormSubmissionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  template?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSaveTagsArgs = {
  children?: InputMaybe<Array<Scalars['Int']['input']>>;
  customTags?: InputMaybe<Array<Scalars['Int']['input']>>;
  learningOutcomes?: InputMaybe<Array<Scalars['Int']['input']>>;
  newCustomTags?: InputMaybe<Array<Scalars['String']['input']>>;
  nextActivities?: InputMaybe<Array<Scalars['Int']['input']>>;
  nextReports?: InputMaybe<Array<Scalars['Int']['input']>>;
  objId: Scalars['Int']['input'];
  objType: Scalars['String']['input'];
  rooms?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationUpdateActivityCategoryArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
};


export type MutationUpdateSmartFormArgs = {
  config?: InputMaybe<Scalars['JSON']['input']>;
  gridMode: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  layout: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  serviceId: Scalars['ID']['input'];
};

export type Note = {
  __typename?: 'Note';
  ackAt?: Maybe<Scalars['String']['output']>;
  ackBy?: Maybe<Scalars['String']['output']>;
  channel?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdBy: Scalars['String']['output'];
  createdByName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  parentId: Scalars['String']['output'];
  parentName: Scalars['String']['output'];
  reply?: Maybe<Scalars['String']['output']>;
};

export type Notification = {
  __typename?: 'Notification';
  actionType: Scalars['String']['output'];
  capture?: Maybe<Capture>;
  comment?: Maybe<Comment>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  objId: Scalars['ID']['output'];
  objType: Scalars['String']['output'];
  report?: Maybe<Report>;
  seenBy?: Maybe<Array<Scalars['ID']['output']>>;
};

export type NotificationCollectionMetadata = {
  __typename?: 'NotificationCollectionMetadata';
  total: Scalars['Int']['output'];
};

export type NotificationMetadata = {
  __typename?: 'NotificationMetadata';
  notificationIds: Array<Scalars['Int']['output']>;
  objType: Scalars['String']['output'];
  seenIds: Array<Scalars['Int']['output']>;
  total: Scalars['Int']['output'];
  unseenCount: Scalars['Int']['output'];
};

export type NotificationWithMetadata = {
  __typename?: 'NotificationWithMetadata';
  metadata: NotificationCollectionMetadata;
  notifications: Array<Notification>;
};

export type Parent = {
  __typename?: 'Parent';
  balance?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  everything?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoices?: Maybe<Array<Invoice>>;
  lastName: Scalars['String']['output'];
  payments?: Maybe<Array<Payment>>;
  phone?: Maybe<Scalars['String']['output']>;
};


export type ParentBalanceArgs = {
  dateTo?: InputMaybe<Scalars['String']['input']>;
};


export type ParentPaymentsArgs = {
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
};

export type ParentTwo = {
  __typename?: 'ParentTwo';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  paymentDate: Scalars['String']['output'];
  transactionDate: Scalars['String']['output'];
};

/** The query root of this schema */
export type Query = {
  __typename?: 'Query';
  /** Find a service by ID */
  service: Service;
};


/** The query root of this schema */
export type QueryServiceArgs = {
  id: Scalars['ID']['input'];
};

export type Report = {
  __typename?: 'Report';
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  date: Scalars['String']['output'];
  history?: Maybe<Array<ReportEvent>>;
  id: Scalars['ID']['output'];
  isPublic: Scalars['Boolean']['output'];
  isTemplate?: Maybe<Scalars['Boolean']['output']>;
  smartFormSubmissionIds?: Maybe<Array<Scalars['Int']['output']>>;
  status: Scalars['String']['output'];
  tags?: Maybe<Tag>;
  template?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userEmail: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type ReportEvent = {
  __typename?: 'ReportEvent';
  createdAt: Scalars['String']['output'];
  event: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  userEmail: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type RequiredDoc = {
  __typename?: 'RequiredDoc';
  active: Scalars['Boolean']['output'];
  channel: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  docData?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  mandatory: Scalars['Boolean']['output'];
};

/** Autogenerated return type of RevertReport */
export type RevertReportPayload = {
  __typename?: 'RevertReportPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  report?: Maybe<Report>;
};

export type Room = {
  __typename?: 'Room';
  fdcEducatorId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inactive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of SaveActivity */
export type SaveActivityPayload = {
  __typename?: 'SaveActivityPayload';
  activity?: Maybe<Activity>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated return type of SaveCapture */
export type SaveCapturePayload = {
  __typename?: 'SaveCapturePayload';
  capture?: Maybe<Capture>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated return type of SaveReport */
export type SaveReportPayload = {
  __typename?: 'SaveReportPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  report?: Maybe<Report>;
};

/** Autogenerated return type of SaveTags */
export type SaveTagsPayload = {
  __typename?: 'SaveTagsPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Service = {
  __typename?: 'Service';
  activities?: Maybe<Array<Activity>>;
  activityCategories?: Maybe<Array<ActivityCategory>>;
  applications?: Maybe<Array<App>>;
  bookedSessions?: Maybe<Array<ServiceSession>>;
  captures?: Maybe<Array<Capture>>;
  ccsPayments?: Maybe<Array<CcsPaymentItem>>;
  comments?: Maybe<Array<Comment>>;
  customTags?: Maybe<Array<CustomTag>>;
  educators?: Maybe<Array<CcsPerson>>;
  enrolments: Array<Enrolment>;
  formConfig: Scalars['String']['output'];
  formConfigurations?: Maybe<Array<FormConfiguration>>;
  forms?: Maybe<Array<Form>>;
  hasPaymentGateway?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  learningOutcomes?: Maybe<Array<LearningOutcome>>;
  name: Scalars['String']['output'];
  notes?: Maybe<Array<Note>>;
  notifications?: Maybe<NotificationWithMetadata>;
  notificationsMetadata?: Maybe<Array<NotificationMetadata>>;
  report?: Maybe<Report>;
  reports?: Maybe<Array<Report>>;
  requiredDocs?: Maybe<Array<RequiredDoc>>;
  rooms: Array<Room>;
  sessions?: Maybe<Array<ServiceSession>>;
  sessionsAvailable?: Maybe<Array<ServiceSession>>;
  smartForm?: Maybe<SmartForm>;
  staffAtts?: Maybe<Array<StaffAtt>>;
  templateGroups: TemplateGroups;
  type: Scalars['String']['output'];
  tzName?: Maybe<Scalars['String']['output']>;
  userOrganisationRoles?: Maybe<Array<User>>;
};


export type ServiceActivitiesArgs = {
  children?: InputMaybe<Array<Scalars['Int']['input']>>;
  customTags?: InputMaybe<Array<Scalars['Int']['input']>>;
  dateFrom: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  learningOutcomes?: InputMaybe<Array<Scalars['Int']['input']>>;
  rooms?: InputMaybe<Array<Scalars['Int']['input']>>;
  style?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type ServiceApplicationsArgs = {
  status: Scalars['String']['input'];
};


export type ServiceBookedSessionsArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type ServiceCapturesArgs = {
  children?: InputMaybe<Array<Scalars['Int']['input']>>;
  customTags?: InputMaybe<Array<Scalars['Int']['input']>>;
  dateFrom: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  learningOutcomes?: InputMaybe<Array<Scalars['Int']['input']>>;
  rooms?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type ServiceCcsPaymentsArgs = {
  weekEnding: Scalars['String']['input'];
};


export type ServiceCommentsArgs = {
  isInternalEvaluation?: InputMaybe<Scalars['Boolean']['input']>;
  objId: Scalars['Int']['input'];
  objType: Scalars['String']['input'];
};


export type ServiceEnrolmentsArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  fdcEducatorId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type ServiceFormsArgs = {
  formDate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  updatedSince?: InputMaybe<Scalars['String']['input']>;
};


export type ServiceNotesArgs = {
  createdSince?: InputMaybe<Scalars['String']['input']>;
};


export type ServiceNotificationsArgs = {
  isInternalEvaluation?: InputMaybe<Scalars['Boolean']['input']>;
  objType?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type ServiceNotificationsMetadataArgs = {
  objType?: InputMaybe<Scalars['String']['input']>;
};


export type ServiceReportArgs = {
  id: Scalars['Int']['input'];
};


export type ServiceReportsArgs = {
  children?: InputMaybe<Array<Scalars['Int']['input']>>;
  customTags?: InputMaybe<Array<Scalars['Int']['input']>>;
  dateFrom: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  learningOutcomes?: InputMaybe<Array<Scalars['Int']['input']>>;
  rooms?: InputMaybe<Array<Scalars['Int']['input']>>;
  template?: InputMaybe<Scalars['String']['input']>;
};


export type ServiceSessionsArgs = {
  date?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  room?: InputMaybe<Scalars['String']['input']>;
};


export type ServiceSessionsAvailableArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  enrolmentId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type ServiceSmartFormArgs = {
  id: Scalars['ID']['input'];
};


export type ServiceStaffAttsArgs = {
  date: Scalars['String']['input'];
};


export type ServiceTemplateGroupsArgs = {
  dateFrom: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
};


export type ServiceUserOrganisationRolesArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceSession = {
  __typename?: 'ServiceSession';
  absent?: Maybe<Scalars['Boolean']['output']>;
  attendanceStatus?: Maybe<Scalars['String']['output']>;
  bookingId?: Maybe<Scalars['String']['output']>;
  bookingPattern?: Maybe<Scalars['String']['output']>;
  bookingStyle?: Maybe<Scalars['String']['output']>;
  ccsMaxHrs?: Maybe<Scalars['String']['output']>;
  ccsPercentage?: Maybe<Scalars['String']['output']>;
  ccsWithholding?: Maybe<Scalars['String']['output']>;
  childFirstName?: Maybe<Scalars['String']['output']>;
  childId?: Maybe<Scalars['ID']['output']>;
  childLastName?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  enrolmentId?: Maybe<Scalars['ID']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  inOut?: Maybe<Array<SessionInOut>>;
  miscFees?: Maybe<Array<SessionMiscFee>>;
  noObservation?: Maybe<Scalars['Boolean']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  room?: Maybe<Scalars['String']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  sessionCodeId?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SessionInOut = {
  __typename?: 'SessionInOut';
  confirmHealthy?: Maybe<Scalars['String']['output']>;
  estDepTime?: Maybe<Scalars['String']['output']>;
  in?: Maybe<Scalars['String']['output']>;
  inBy?: Maybe<Scalars['String']['output']>;
  inEditedBy?: Maybe<Scalars['String']['output']>;
  out?: Maybe<Scalars['String']['output']>;
  outBy?: Maybe<Scalars['String']['output']>;
  outEditedBy?: Maybe<Scalars['String']['output']>;
  whoSignOut?: Maybe<Scalars['String']['output']>;
};

export type SessionMiscFee = {
  __typename?: 'SessionMiscFee';
  amount: Scalars['Float']['output'];
  code: Scalars['String']['output'];
  dailySessionId: Scalars['String']['output'];
  date: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fdcEducatorId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type SmartForm = {
  __typename?: 'SmartForm';
  config?: Maybe<Scalars['String']['output']>;
  gridMode: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  layout: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organisationId: Scalars['Int']['output'];
};

export type StaffAtt = {
  __typename?: 'StaffAtt';
  date: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  in: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  out: Scalars['String']['output'];
  roomName?: Maybe<Scalars['String']['output']>;
  unpaid: Scalars['Boolean']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  children?: Maybe<Array<Scalars['Int']['output']>>;
  customTags?: Maybe<Array<Scalars['Int']['output']>>;
  learningOutcomes?: Maybe<Array<Scalars['Int']['output']>>;
  nextActivities?: Maybe<Array<Scalars['Int']['output']>>;
  nextReports?: Maybe<Array<Scalars['Int']['output']>>;
  rooms?: Maybe<Array<Scalars['Int']['output']>>;
};

export type TemplateGroups = {
  __typename?: 'TemplateGroups';
  templates: Array<Scalars['String']['output']>;
};

/** Autogenerated return type of UpdateActivityCategory */
export type UpdateActivityCategoryPayload = {
  __typename?: 'UpdateActivityCategoryPayload';
  activityCategory?: Maybe<ActivityCategory>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated return type of UpdateSmartForm */
export type UpdateSmartFormPayload = {
  __typename?: 'UpdateSmartFormPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  form?: Maybe<SmartForm>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  userOrgDetails?: Maybe<UserOrgDetails>;
};

export type UserOrgDetails = {
  __typename?: 'UserOrgDetails';
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  roleEnd?: Maybe<Scalars['String']['output']>;
  roleStart?: Maybe<Scalars['String']['output']>;
};

export type GetServiceInfoQueryVariables = Exact<{
  srvId: Scalars['ID']['input'];
}>;


export type GetServiceInfoQuery = { __typename?: 'Query', service: { __typename?: 'Service', name: string, type: string, tzName?: string | null } };

export type GetAvailableBookingsQueryVariables = Exact<{
  srvId: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  enrolmentId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAvailableBookingsQuery = { __typename?: 'Query', service: { __typename?: 'Service', id: string, sessionsAvailable?: Array<{ __typename?: 'ServiceSession', date?: string | null, enrolmentId?: string | null, bookingId?: string | null, productId?: string | null, room?: string | null, code?: string | null, fee?: number | null, startTime?: string | null, endTime?: string | null, status?: string | null, absent?: boolean | null, sessionCodeId?: string | null, roomId?: string | null }> | null } };

export type GetSessionsQueryVariables = Exact<{
  srvId: Scalars['ID']['input'];
  date?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  room?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSessionsQuery = { __typename?: 'Query', service: { __typename?: 'Service', sessions?: Array<{ __typename?: 'ServiceSession', date?: string | null, enrolmentId?: string | null, bookingId?: string | null, productId?: string | null, room?: string | null, code?: string | null, fee?: number | null, startTime?: string | null, endTime?: string | null, status?: string | null, absent?: boolean | null, sessionCodeId?: string | null, roomId?: string | null, childFirstName?: string | null, childLastName?: string | null, miscFees?: Array<{ __typename?: 'SessionMiscFee', id: string, code: string, date: string, amount: number, description?: string | null, fdcEducatorId?: string | null, dailySessionId: string }> | null }> | null } };

export type GetBookedSessionsQueryVariables = Exact<{
  srvId: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetBookedSessionsQuery = { __typename?: 'Query', service: { __typename?: 'Service', bookedSessions?: Array<{ __typename?: 'ServiceSession', date?: string | null, enrolmentId?: string | null, bookingId?: string | null, productId?: string | null, room?: string | null, code?: string | null, fee?: number | null, startTime?: string | null, endTime?: string | null, status?: string | null, absent?: boolean | null, sessionCodeId?: string | null, roomId?: string | null, childFirstName?: string | null, childLastName?: string | null, miscFees?: Array<{ __typename?: 'SessionMiscFee', id: string, code: string, date: string, amount: number, description?: string | null, fdcEducatorId?: string | null, dailySessionId: string }> | null }> | null } };

export type CreateCasualBookingMutationVariables = Exact<{
  enrolmentId: Scalars['String']['input'];
  productId: Scalars['String']['input'];
}>;


export type CreateCasualBookingMutation = { __typename?: 'Mutation', createSession?: { __typename?: 'CreateSessionSalePayload', errors?: Array<string> | null, session?: { __typename?: 'ServiceSession', date?: string | null, bookingId?: string | null } | null } | null };

export type GetChildrenParentListQueryVariables = Exact<{
  srvId: Scalars['ID']['input'];
  enrolmentId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetChildrenParentListQuery = { __typename?: 'Query', service: { __typename?: 'Service', enrolments: Array<{ __typename?: 'Enrolment', id: string, status: string, parentId: string, childId: string, child: { __typename?: 'Child', id: string, firstName: string, lastName: string, contacts?: Array<{ __typename?: 'Contact', firstName: string, lastName: string, role: string, email?: string | null, obsPlatformAuthorised?: boolean | null }> | null }, parent1: { __typename?: 'Parent', id: string, firstName: string, lastName: string, email?: string | null }, parent2?: { __typename?: 'ParentTwo', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null } | null }> } };

export type GetCommentsQueryVariables = Exact<{
  srvId: Scalars['ID']['input'];
  objType: Scalars['String']['input'];
  objId: Scalars['Int']['input'];
  isInternalEvaluation?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetCommentsQuery = { __typename?: 'Query', service: { __typename?: 'Service', comments?: Array<{ __typename?: 'Comment', id: string, body: string, isInternalEvaluation: boolean, objId: string, objType: string, createdAt: string, updatedAt?: string | null, deleted: boolean, user: { __typename?: 'User', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null } }> | null } };

export type PostCreateCommentMutationVariables = Exact<{
  serviceId: Scalars['ID']['input'];
  objType: Scalars['String']['input'];
  objId: Scalars['ID']['input'];
  body: Scalars['String']['input'];
  isInternalEvaluation: Scalars['Boolean']['input'];
}>;


export type PostCreateCommentMutation = { __typename?: 'Mutation', createComment?: { __typename?: 'CreateCommentPayload', errors?: Array<string> | null, comment?: { __typename?: 'Comment', id: string, body: string, serviceId: string, objType: string, objId: string, isInternalEvaluation: boolean, createdAt: string, updatedAt?: string | null, user: { __typename?: 'User', firstName?: string | null, lastName?: string | null, id?: string | null } } | null } | null };

export type PostEditCommentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  body: Scalars['String']['input'];
  isInternalEvaluation?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type PostEditCommentMutation = { __typename?: 'Mutation', editComment?: { __typename?: 'EditCommentPayload', errors?: Array<string> | null, comment?: { __typename?: 'Comment', id: string, body: string, createdAt: string, updatedAt?: string | null, objId: string, objType: string, serviceId: string, user: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null } } | null } | null };

export type PostDeleteCommentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PostDeleteCommentMutation = { __typename?: 'Mutation', deleteComment?: { __typename?: 'DeleteCommentPayload', success?: boolean | null, errors?: Array<string> | null } | null };

export type GetCapturesQueryVariables = Exact<{
  srvId: Scalars['ID']['input'];
  dateFrom: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  childrenId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  customTagsId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  learningOutcomesId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  roomsId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type GetCapturesQuery = { __typename?: 'Query', service: { __typename?: 'Service', captures?: Array<{ __typename?: 'Capture', id: string, date: string, createdAt: string, updatedAt?: string | null, url?: string | null, filename?: string | null, userEmail: string, userName: string, mime?: string | null, caption?: string | null, tags?: { __typename?: 'Tag', children?: Array<number> | null, learningOutcomes?: Array<number> | null, customTags?: Array<number> | null, rooms?: Array<number> | null } | null }> | null } };

export type PostCreateCaptureMutationVariables = Exact<{
  srvid: Scalars['String']['input'];
  date: Scalars['String']['input'];
  fileData: Scalars['String']['input'];
}>;


export type PostCreateCaptureMutation = { __typename?: 'Mutation', createCapture?: { __typename?: 'CreateCapturePayload', captures?: Array<{ __typename?: 'Capture', id: string, date: string, errors?: Array<string> | null }> | null } | null };

export type PostSaveCaptureMutationVariables = Exact<{
  srvid: Scalars['String']['input'];
  captureId: Scalars['ID']['input'];
  caption?: InputMaybe<Scalars['String']['input']>;
}>;


export type PostSaveCaptureMutation = { __typename?: 'Mutation', saveCapture?: { __typename?: 'SaveCapturePayload', errors?: Array<string> | null, capture?: { __typename?: 'Capture', id: string } | null } | null };

export type PostDeleteKindyHubObjectMutationVariables = Exact<{
  objId: Scalars['Int']['input'];
  objType: Scalars['String']['input'];
}>;


export type PostDeleteKindyHubObjectMutation = { __typename?: 'Mutation', deleteKindyhubObject?: { __typename?: 'DeleteKindyhubObjectPayload', errors?: Array<string> | null, status?: string | null } | null };

export type GetRoomsQueryVariables = Exact<{
  srvId: Scalars['ID']['input'];
}>;


export type GetRoomsQuery = { __typename?: 'Query', service: { __typename?: 'Service', rooms: Array<{ __typename?: 'Room', id: string, inactive?: boolean | null, name?: string | null }> } };

export type GetReportQueryVariables = Exact<{
  srvId: Scalars['ID']['input'];
  reportId: Scalars['Int']['input'];
}>;


export type GetReportQuery = { __typename?: 'Query', service: { __typename?: 'Service', report?: { __typename?: 'Report', id: string, title?: string | null, date: string, content?: string | null, createdAt: string, updatedAt?: string | null, userEmail: string, userName: string, isTemplate?: boolean | null, theme?: string | null, status: string, smartFormSubmissionIds?: Array<number> | null, tags?: { __typename?: 'Tag', children?: Array<number> | null, customTags?: Array<number> | null, learningOutcomes?: Array<number> | null, rooms?: Array<number> | null, nextActivities?: Array<number> | null } | null, history?: Array<{ __typename?: 'ReportEvent', id: string, createdAt: string, event: string, userEmail: string, userName: string }> | null } | null } };

export type GetReportsQueryVariables = Exact<{
  srvId: Scalars['ID']['input'];
  dateFrom: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  childrenId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  customTagsId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  learningOutcomesId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  roomsId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetReportsQuery = { __typename?: 'Query', service: { __typename?: 'Service', reports?: Array<{ __typename?: 'Report', title?: string | null, date: string, createdAt: string, id: string, updatedAt?: string | null, userEmail: string, userName: string, status: string, template?: string | null, isTemplate?: boolean | null, theme?: string | null, tags?: { __typename?: 'Tag', children?: Array<number> | null, learningOutcomes?: Array<number> | null, customTags?: Array<number> | null, rooms?: Array<number> | null, nextActivities?: Array<number> | null } | null }> | null } };

export type GetTemplateQueryVariables = Exact<{
  srvId: Scalars['ID']['input'];
  reportId: Scalars['Int']['input'];
}>;


export type GetTemplateQuery = { __typename?: 'Query', service: { __typename?: 'Service', report?: { __typename?: 'Report', id: string, title?: string | null, date: string, content?: string | null, isTemplate?: boolean | null, theme?: string | null, smartFormSubmissionIds?: Array<number> | null, tags?: { __typename?: 'Tag', children?: Array<number> | null, customTags?: Array<number> | null, learningOutcomes?: Array<number> | null, rooms?: Array<number> | null, nextActivities?: Array<number> | null } | null } | null } };

export type PostSaveReportMutationVariables = Exact<{
  srvid: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  reportid?: InputMaybe<Scalars['ID']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  smartFormSubmissionIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
}>;


export type PostSaveReportMutation = { __typename?: 'Mutation', saveReport?: { __typename?: 'SaveReportPayload', errors?: Array<string> | null, report?: { __typename?: 'Report', id: string, date: string, content?: string | null, isTemplate?: boolean | null, smartFormSubmissionIds?: Array<number> | null, status: string, theme?: string | null } | null } | null };

export type PostSaveTagsMutationVariables = Exact<{
  objType: Scalars['String']['input'];
  objId: Scalars['Int']['input'];
  rooms?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  children?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  learningOutcomes?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  customTags?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  newCustomTags?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  nextActivities?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type PostSaveTagsMutation = { __typename?: 'Mutation', saveTags?: { __typename?: 'SaveTagsPayload', errors?: Array<string> | null, status?: string | null } | null };

export type PostActionReportMutationVariables = Exact<{
  reportid: Scalars['Int']['input'];
  action: Scalars['String']['input'];
}>;


export type PostActionReportMutation = { __typename?: 'Mutation', actionReport?: { __typename?: 'ActionReportPayload', errors?: Array<string> | null, status?: string | null } | null };

export type GetRequiredDocsQueryVariables = Exact<{
  srvId: Scalars['ID']['input'];
}>;


export type GetRequiredDocsQuery = { __typename?: 'Query', service: { __typename?: 'Service', requiredDocs?: Array<{ __typename?: 'RequiredDoc', active: boolean, channel: string, description?: string | null, docData?: string | null, id: string, label: string, mandatory: boolean }> | null } };


export const GetServiceInfoDocument = gql`
    query getServiceInfo($srvId: ID!) {
  service(id: $srvId) {
    name
    type
    tzName
  }
}
    `;
export const GetAvailableBookingsDocument = gql`
    query getAvailableBookings($srvId: ID!, $startDate: String, $endDate: String, $enrolmentId: String) {
  service(id: $srvId) {
    id
    sessionsAvailable(
      startDate: $startDate
      endDate: $endDate
      enrolmentId: $enrolmentId
    ) {
      date
      enrolmentId
      bookingId
      productId
      room
      code
      fee
      startTime
      endTime
      status
      absent
      sessionCodeId
      roomId
    }
  }
}
    `;
export const GetSessionsDocument = gql`
    query getSessions($srvId: ID!, $date: String, $dateTo: String, $room: String) {
  service(id: $srvId) {
    sessions(date: $date, dateTo: $dateTo, room: $room) {
      date
      enrolmentId
      bookingId
      productId
      room
      code
      fee
      startTime
      endTime
      status
      absent
      sessionCodeId
      roomId
      childFirstName
      childLastName
      miscFees {
        id
        code
        date
        amount
        description
        fdcEducatorId
        dailySessionId
      }
    }
  }
}
    `;
export const GetBookedSessionsDocument = gql`
    query getBookedSessions($srvId: ID!, $startDate: String, $roomId: String, $endDate: String) {
  service(id: $srvId) {
    bookedSessions(startDate: $startDate, roomId: $roomId, endDate: $endDate) {
      date
      enrolmentId
      bookingId
      productId
      room
      code
      fee
      startTime
      endTime
      status
      absent
      sessionCodeId
      roomId
      childFirstName
      childLastName
      miscFees {
        id
        code
        date
        amount
        description
        fdcEducatorId
        dailySessionId
      }
    }
  }
}
    `;
export const CreateCasualBookingDocument = gql`
    mutation createCasualBooking($enrolmentId: String!, $productId: String!) {
  createSession(enrolmentId: $enrolmentId, productId: $productId) {
    errors
    session {
      date
      bookingId
    }
  }
}
    `;
export const GetChildrenParentListDocument = gql`
    query getChildrenParentList($srvId: ID!, $enrolmentId: String) {
  service(id: $srvId) {
    enrolments(id: $enrolmentId) {
      id
      status
      parentId
      childId
      child {
        id
        firstName
        lastName
        contacts {
          firstName
          lastName
          role
          email
          obsPlatformAuthorised
        }
      }
      parent1 {
        id
        firstName
        lastName
        email
      }
      parent2 {
        id
        firstName
        lastName
        email
      }
    }
  }
}
    `;
export const GetCommentsDocument = gql`
    query getComments($srvId: ID!, $objType: String!, $objId: Int!, $isInternalEvaluation: Boolean) {
  service(id: $srvId) {
    comments(
      objType: $objType
      objId: $objId
      isInternalEvaluation: $isInternalEvaluation
    ) {
      id
      body
      isInternalEvaluation
      objId
      objType
      createdAt
      updatedAt
      deleted
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
}
    `;
export const PostCreateCommentDocument = gql`
    mutation postCreateComment($serviceId: ID!, $objType: String!, $objId: ID!, $body: String!, $isInternalEvaluation: Boolean!) {
  createComment(
    serviceId: $serviceId
    objType: $objType
    objId: $objId
    body: $body
    isInternalEvaluation: $isInternalEvaluation
  ) {
    comment {
      id
      body
      serviceId
      objType
      objId
      isInternalEvaluation
      createdAt
      updatedAt
      user {
        firstName
        lastName
        id
      }
    }
    errors
  }
}
    `;
export const PostEditCommentDocument = gql`
    mutation postEditComment($id: ID!, $body: String!, $isInternalEvaluation: Boolean) {
  editComment(id: $id, body: $body, isInternalEvaluation: $isInternalEvaluation) {
    comment {
      id
      body
      createdAt
      updatedAt
      objId
      objType
      serviceId
      user {
        id
        firstName
        lastName
        email
      }
    }
    errors
  }
}
    `;
export const PostDeleteCommentDocument = gql`
    mutation postDeleteComment($id: ID!) {
  deleteComment(id: $id) {
    success
    errors
  }
}
    `;
export const GetCapturesDocument = gql`
    query getCaptures($srvId: ID!, $dateFrom: String!, $dateTo: String, $childrenId: [Int!], $customTagsId: [Int!], $learningOutcomesId: [Int!], $roomsId: [Int!]) {
  service(id: $srvId) {
    captures(
      dateFrom: $dateFrom
      dateTo: $dateTo
      children: $childrenId
      customTags: $customTagsId
      learningOutcomes: $learningOutcomesId
      rooms: $roomsId
    ) {
      id
      date
      createdAt
      updatedAt
      url
      filename
      userEmail
      userName
      mime
      caption
      tags {
        children
        learningOutcomes
        customTags
        rooms
      }
    }
  }
}
    `;
export const PostCreateCaptureDocument = gql`
    mutation postCreateCapture($srvid: String!, $date: String!, $fileData: String!) {
  createCapture(serviceId: $srvid, date: $date, fileData: $fileData) {
    captures {
      id
      date
      errors
    }
  }
}
    `;
export const PostSaveCaptureDocument = gql`
    mutation postSaveCapture($srvid: String!, $captureId: ID!, $caption: String) {
  saveCapture(serviceId: $srvid, captureId: $captureId, caption: $caption) {
    capture {
      id
    }
    errors
  }
}
    `;
export const PostDeleteKindyHubObjectDocument = gql`
    mutation postDeleteKindyHubObject($objId: Int!, $objType: String!) {
  deleteKindyhubObject(objId: $objId, objType: $objType) {
    errors
    status
  }
}
    `;
export const GetRoomsDocument = gql`
    query getRooms($srvId: ID!) {
  service(id: $srvId) {
    rooms {
      id
      inactive
      name
    }
  }
}
    `;
export const GetReportDocument = gql`
    query getReport($srvId: ID!, $reportId: Int!) {
  service(id: $srvId) {
    report(id: $reportId) {
      id
      title
      date
      content
      createdAt
      updatedAt
      userEmail
      userName
      isTemplate
      theme
      status
      smartFormSubmissionIds
      tags {
        children
        customTags
        learningOutcomes
        rooms
        nextActivities
      }
      history {
        id
        createdAt
        event
        userEmail
        userName
      }
    }
  }
}
    `;
export const GetReportsDocument = gql`
    query getReports($srvId: ID!, $dateFrom: String!, $dateTo: String, $childrenId: [Int!], $customTagsId: [Int!], $learningOutcomesId: [Int!], $roomsId: [Int!], $template: String) {
  service(id: $srvId) {
    reports(
      dateFrom: $dateFrom
      dateTo: $dateTo
      children: $childrenId
      customTags: $customTagsId
      learningOutcomes: $learningOutcomesId
      rooms: $roomsId
      template: $template
    ) {
      title
      date
      createdAt
      id
      updatedAt
      userEmail
      userName
      status
      template
      isTemplate
      theme
      tags {
        children
        learningOutcomes
        customTags
        rooms
        nextActivities
      }
    }
  }
}
    `;
export const GetTemplateDocument = gql`
    query getTemplate($srvId: ID!, $reportId: Int!) {
  service(id: $srvId) {
    report(id: $reportId) {
      id
      title
      date
      content
      isTemplate
      theme
      smartFormSubmissionIds
      tags {
        children
        customTags
        learningOutcomes
        rooms
        nextActivities
      }
    }
  }
}
    `;
export const PostSaveReportDocument = gql`
    mutation postSaveReport($srvid: String!, $title: String, $date: String, $content: String, $reportid: ID, $theme: String, $smartFormSubmissionIds: [Int!], $template: String) {
  saveReport(
    serviceId: $srvid
    title: $title
    date: $date
    content: $content
    reportId: $reportid
    theme: $theme
    smartFormSubmissionIds: $smartFormSubmissionIds
    template: $template
  ) {
    errors
    report {
      id
      date
      content
      isTemplate
      smartFormSubmissionIds
      status
      theme
    }
  }
}
    `;
export const PostSaveTagsDocument = gql`
    mutation postSaveTags($objType: String!, $objId: Int!, $rooms: [Int!], $children: [Int!], $learningOutcomes: [Int!], $customTags: [Int!], $newCustomTags: [String!], $nextActivities: [Int!]) {
  saveTags(
    objType: $objType
    objId: $objId
    rooms: $rooms
    children: $children
    learningOutcomes: $learningOutcomes
    customTags: $customTags
    newCustomTags: $newCustomTags
    nextActivities: $nextActivities
  ) {
    errors
    status
  }
}
    `;
export const PostActionReportDocument = gql`
    mutation postActionReport($reportid: Int!, $action: String!) {
  actionReport(id: $reportid, action: $action) {
    errors
    status
  }
}
    `;
export const GetRequiredDocsDocument = gql`
    query getRequiredDocs($srvId: ID!) {
  service(id: $srvId) {
    requiredDocs {
      active
      channel
      description
      docData
      id
      label
      mandatory
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getServiceInfo(variables: GetServiceInfoQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetServiceInfoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetServiceInfoQuery>(GetServiceInfoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getServiceInfo', 'query');
    },
    getAvailableBookings(variables: GetAvailableBookingsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAvailableBookingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAvailableBookingsQuery>(GetAvailableBookingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAvailableBookings', 'query');
    },
    getSessions(variables: GetSessionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSessionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSessionsQuery>(GetSessionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSessions', 'query');
    },
    getBookedSessions(variables: GetBookedSessionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBookedSessionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBookedSessionsQuery>(GetBookedSessionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getBookedSessions', 'query');
    },
    createCasualBooking(variables: CreateCasualBookingMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateCasualBookingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCasualBookingMutation>(CreateCasualBookingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createCasualBooking', 'mutation');
    },
    getChildrenParentList(variables: GetChildrenParentListQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetChildrenParentListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetChildrenParentListQuery>(GetChildrenParentListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChildrenParentList', 'query');
    },
    getComments(variables: GetCommentsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCommentsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCommentsQuery>(GetCommentsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getComments', 'query');
    },
    postCreateComment(variables: PostCreateCommentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostCreateCommentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostCreateCommentMutation>(PostCreateCommentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postCreateComment', 'mutation');
    },
    postEditComment(variables: PostEditCommentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostEditCommentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostEditCommentMutation>(PostEditCommentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postEditComment', 'mutation');
    },
    postDeleteComment(variables: PostDeleteCommentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostDeleteCommentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostDeleteCommentMutation>(PostDeleteCommentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postDeleteComment', 'mutation');
    },
    getCaptures(variables: GetCapturesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCapturesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCapturesQuery>(GetCapturesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCaptures', 'query');
    },
    postCreateCapture(variables: PostCreateCaptureMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostCreateCaptureMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostCreateCaptureMutation>(PostCreateCaptureDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postCreateCapture', 'mutation');
    },
    postSaveCapture(variables: PostSaveCaptureMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostSaveCaptureMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostSaveCaptureMutation>(PostSaveCaptureDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postSaveCapture', 'mutation');
    },
    postDeleteKindyHubObject(variables: PostDeleteKindyHubObjectMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostDeleteKindyHubObjectMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostDeleteKindyHubObjectMutation>(PostDeleteKindyHubObjectDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postDeleteKindyHubObject', 'mutation');
    },
    getRooms(variables: GetRoomsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRoomsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRoomsQuery>(GetRoomsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRooms', 'query');
    },
    getReport(variables: GetReportQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetReportQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReportQuery>(GetReportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReport', 'query');
    },
    getReports(variables: GetReportsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetReportsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReportsQuery>(GetReportsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReports', 'query');
    },
    getTemplate(variables: GetTemplateQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTemplateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTemplateQuery>(GetTemplateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getTemplate', 'query');
    },
    postSaveReport(variables: PostSaveReportMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostSaveReportMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostSaveReportMutation>(PostSaveReportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postSaveReport', 'mutation');
    },
    postSaveTags(variables: PostSaveTagsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostSaveTagsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostSaveTagsMutation>(PostSaveTagsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postSaveTags', 'mutation');
    },
    postActionReport(variables: PostActionReportMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostActionReportMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostActionReportMutation>(PostActionReportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postActionReport', 'mutation');
    },
    getRequiredDocs(variables: GetRequiredDocsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRequiredDocsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRequiredDocsQuery>(GetRequiredDocsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRequiredDocs', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;