/* tslint:disable */
/* eslint-disable */
/**
 * Smart Central - Parent Application
 * Smart Central - Parent Application API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { NoteReply } from '../models';
// @ts-ignore
import { Notes } from '../models';
// @ts-ignore
import { PostAckReplyNotes200Response } from '../models';
// @ts-ignore
import { StatusOk } from '../models';
// @ts-ignore
import { UserEnrolments } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Acknowledge note
         * @param {number} noteId Note ID to acknowledge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAckNotes: async (noteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('getAckNotes', 'noteId', noteId)
            const localVarPath = `/user/ack_note/{note_id}.json`
                .replace(`{${"note_id"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user enrolments
         * @param {number} [srvId] ID of service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEnrolments: async (srvId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/enrolments.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (srvId !== undefined) {
                localVarQueryParameter['srv_id'] = srvId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user notes
         * @param {number} [srvId] ID of service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotes: async (srvId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/notes.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (srvId !== undefined) {
                localVarQueryParameter['srv_id'] = srvId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reply to note
         * @param {number} noteId Note ID to acknowledge
         * @param {NoteReply} [noteReply] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAckReplyNotes: async (noteId: number, noteReply?: NoteReply, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('postAckReplyNotes', 'noteId', noteId)
            const localVarPath = `/user/ack_and_reply_note/{note_id}.json`
                .replace(`{${"note_id"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteReply, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Acknowledge note
         * @param {number} noteId Note ID to acknowledge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAckNotes(noteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusOk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAckNotes(noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user enrolments
         * @param {number} [srvId] ID of service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserEnrolments(srvId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserEnrolments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserEnrolments(srvId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user notes
         * @param {number} [srvId] ID of service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserNotes(srvId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserNotes(srvId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reply to note
         * @param {number} noteId Note ID to acknowledge
         * @param {NoteReply} [noteReply] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAckReplyNotes(noteId: number, noteReply?: NoteReply, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostAckReplyNotes200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAckReplyNotes(noteId, noteReply, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Acknowledge note
         * @param {number} noteId Note ID to acknowledge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAckNotes(noteId: number, options?: any): AxiosPromise<StatusOk> {
            return localVarFp.getAckNotes(noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user enrolments
         * @param {number} [srvId] ID of service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEnrolments(srvId?: number, options?: any): AxiosPromise<UserEnrolments> {
            return localVarFp.getUserEnrolments(srvId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user notes
         * @param {number} [srvId] ID of service
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotes(srvId?: number, options?: any): AxiosPromise<Notes> {
            return localVarFp.getUserNotes(srvId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reply to note
         * @param {number} noteId Note ID to acknowledge
         * @param {NoteReply} [noteReply] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAckReplyNotes(noteId: number, noteReply?: NoteReply, options?: any): AxiosPromise<PostAckReplyNotes200Response> {
            return localVarFp.postAckReplyNotes(noteId, noteReply, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Acknowledge note
     * @param {number} noteId Note ID to acknowledge
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAckNotes(noteId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAckNotes(noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user enrolments
     * @param {number} [srvId] ID of service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserEnrolments(srvId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserEnrolments(srvId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user notes
     * @param {number} [srvId] ID of service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserNotes(srvId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserNotes(srvId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reply to note
     * @param {number} noteId Note ID to acknowledge
     * @param {NoteReply} [noteReply] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postAckReplyNotes(noteId: number, noteReply?: NoteReply, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postAckReplyNotes(noteId, noteReply, options).then((request) => request(this.axios, this.basePath));
    }
}
