const toSCId = (id?: string) => {
  return id
    ? `sf${process.env.NEXT_PUBLIC_APP_ENV?.slice(0, 3)}_${id}`
    : undefined
}

const parseSmartCentralId = (scId: string) => {
  return scId && (scId.split('_').at(1) as string)
}

export { toSCId, parseSmartCentralId }
