import { useRouter } from 'next/router'
import { useGQLClient } from 'common/hooks/use-gql-client'

export const useGqlController = () => {
  const { query, isReady: isRouterReady, push } = useRouter()
  const { gqlClient, isConfigReady } = useGQLClient()

  return {
    isReady: isRouterReady && isConfigReady,
    gqlClient: gqlClient,
    query: query,
    push: push,
  }
}
