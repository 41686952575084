import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useRestController } from 'common/hooks/use-rest-controller'
import { AccountApiFactory } from 'generated/restapi/api/account-api'
import { emitError } from 'common/utils/event-emitter'

export const usePushNotificationController = () => {
  const { config, query, isReady } = useRestController()
  const { srvid } = query

  const { getUserInfo } = AccountApiFactory(config)

  const { data: dataUserInfo, isLoading: isUserInfoLoading } = useQuery(
    ['fetch-user-info'],
    () => getUserInfo(),
    {
      enabled: isReady && !!srvid,
      onError: (err) => {
        let errMessage = err
        if (axios.isAxiosError(err) && err.response) {
          errMessage = err.response.statusText
        }
        emitError(`Error fetching user info. ${errMessage}`)
      },
    }
  )

  const retrievePubKey = async () => {
    let succeedStatus = false
    let response = await fetch(
      `${process.env.NEXT_PUBLIC_SC_NODE_URL}/vapid-public-key`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    )
      .then((res) => {
        succeedStatus = true
        return res
      })
      .catch((error) => {
        return error
      })
    return succeedStatus ? response.json() : 'Unable to connect to server'
  }

  const getServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
      return navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          if (registrations.length > 0) {
            return registrations[0]
          } else {
            return null
          }
        })
    } else {
      return null
    }
  }

  const subscribe = (
    registration: ServiceWorkerRegistration,
    pubKey: string
  ) => {
    // Subscribe to push notifications
    let response = registration.pushManager
      .subscribe({
        // the notification will only be displayed if the user is currently viewing the page
        userVisibleOnly: true,
        applicationServerKey: pubKey,
      })
      .catch(function (error) {
        console.error('Failed to subscribe to push notifications:', error)
        return null
      })
    return response
  }

  const unsubscribeToServer = (channel: string, endpoint: string) => {
    fetch(`${process.env.NEXT_PUBLIC_SC_NODE_URL}/unsubscribe-notification`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        endpoint: endpoint,
        channel: channel,
      }),
    })
  }

  const sendSubscriptionToServer = (
    channel: string,
    subscription: PushSubscription
  ) => {
    fetch(`${process.env.NEXT_PUBLIC_SC_NODE_URL}/save-subscription`, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        subscription: subscription,
        channel: channel,
      }),
    })
  }

  return {
    userInfo: dataUserInfo?.data,
    retrievePubKey,
    getServiceWorker,
    subscribe,
    unsubscribeToServer,
    sendSubscriptionToServer,
  }
}
