import { useCallback, useEffect, useState } from 'react'
import { usePushNotificationController } from 'modules/push-notification/hooks/use-push-notification-controller'

const PushNotificationProvider = () => {
  const pushNotificationController = usePushNotificationController()
  const {
    userInfo,
    retrievePubKey,
    getServiceWorker,
    subscribe,
    unsubscribeToServer,
    sendSubscriptionToServer,
  } = pushNotificationController
  const [subscription, setSubscription] = useState<PushSubscription>()

  // Setup service worker and pushmanager for push notification
  const setupNotification = useCallback(async (channel: string) => {
    // retrieves public keys from server
    const response = await retrievePubKey()
    // if server is up and able to retrieve pubkey
    if (response.publicKey) {
      let pubKey = response.publicKey
      // registers our service worker
      const registration = await getServiceWorker()
      if (registration) {
        // retrieves subscriptionc
        await navigator.serviceWorker.ready
        const sub = await subscribe(registration, pubKey)
        if (sub) {
          // unsubcribe to previous if exists
          if (subscription) {
            unsubscribeToServer(channel, subscription.endpoint)
          }
          setSubscription(sub)
          // listen to our subscribe channel
          sendSubscriptionToServer(channel, sub)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userInfo && userInfo.email) {
      setupNotification(userInfo.email)
    }
  }, [userInfo, setupNotification])

  return <></>
}

export default PushNotificationProvider
