/* tslint:disable */
/* eslint-disable */
/**
 * Smart Central - Parent Application
 * Smart Central - Parent Application API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ChildDocumentFileData } from './child-document-file-data';
import { ChildDocumentUri } from './child-document-uri';

/**
 * 
 * @export
 * @interface ChildDocument
 */
export interface ChildDocument {
    /**
     * 
     * @type {number}
     * @memberof ChildDocument
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildDocument
     */
    'date': string;
    /**
     * 
     * @type {ChildDocumentFileData}
     * @memberof ChildDocument
     */
    'file_data': ChildDocumentFileData;
    /**
     * 
     * @type {number}
     * @memberof ChildDocument
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof ChildDocument
     */
    'service_id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildDocument
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ChildDocument
     */
    'updated_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof ChildDocument
     */
    'deleted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChildDocument
     */
    'is_public': boolean;
    /**
     * 
     * @type {number}
     * @memberof ChildDocument
     */
    'document_id': number;
    /**
     * 
     * @type {number}
     * @memberof ChildDocument
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof ChildDocument
     */
    'type': ChildDocumentTypeEnum;
    /**
     * 
     * @type {ChildDocumentUri}
     * @memberof ChildDocument
     */
    'uri': ChildDocumentUri;
}

export const ChildDocumentTypeEnum = {
    Pdf: 'pdf'
} as const;

export type ChildDocumentTypeEnum = typeof ChildDocumentTypeEnum[keyof typeof ChildDocumentTypeEnum];


