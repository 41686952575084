/* tslint:disable */
/* eslint-disable */
/**
 * Smart Central - Parent Application
 * Smart Central - Parent Application API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttendanceSimple } from '../models';
// @ts-ignore
import { StatusOk } from '../models';
/**
 * AttendanceApi - axios parameter creator
 * @export
 */
export const AttendanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get end of week attendance report
         * @param {number} [srvId] Service ID
         * @param {string} [date] Record date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttendances: async (srvId?: number, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/att_list.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (srvId !== undefined) {
                localVarQueryParameter['srv_id'] = srvId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send confirmation for week attendance
         * @param {number} attId Att ID of week attendance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfirmAttendance: async (attId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attId' is not null or undefined
            assertParamExists('postConfirmAttendance', 'attId', attId)
            const localVarPath = `/user/confirm_attendance/{att_id}.json`
                .replace(`{${"att_id"}}`, encodeURIComponent(String(attId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttendanceApi - functional programming interface
 * @export
 */
export const AttendanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttendanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get end of week attendance report
         * @param {number} [srvId] Service ID
         * @param {string} [date] Record date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttendances(srvId?: number, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttendanceSimple>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttendances(srvId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send confirmation for week attendance
         * @param {number} attId Att ID of week attendance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postConfirmAttendance(attId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusOk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postConfirmAttendance(attId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttendanceApi - factory interface
 * @export
 */
export const AttendanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttendanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get end of week attendance report
         * @param {number} [srvId] Service ID
         * @param {string} [date] Record date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttendances(srvId?: number, date?: string, options?: any): AxiosPromise<Array<AttendanceSimple>> {
            return localVarFp.getAttendances(srvId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send confirmation for week attendance
         * @param {number} attId Att ID of week attendance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConfirmAttendance(attId: number, options?: any): AxiosPromise<StatusOk> {
            return localVarFp.postConfirmAttendance(attId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttendanceApi - object-oriented interface
 * @export
 * @class AttendanceApi
 * @extends {BaseAPI}
 */
export class AttendanceApi extends BaseAPI {
    /**
     * 
     * @summary Get end of week attendance report
     * @param {number} [srvId] Service ID
     * @param {string} [date] Record date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttendanceApi
     */
    public getAttendances(srvId?: number, date?: string, options?: AxiosRequestConfig) {
        return AttendanceApiFp(this.configuration).getAttendances(srvId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send confirmation for week attendance
     * @param {number} attId Att ID of week attendance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttendanceApi
     */
    public postConfirmAttendance(attId: number, options?: AxiosRequestConfig) {
        return AttendanceApiFp(this.configuration).postConfirmAttendance(attId, options).then((request) => request(this.axios, this.basePath));
    }
}
