/* tslint:disable */
/* eslint-disable */
/**
 * Smart Central - Parent Application
 * Smart Central - Parent Application API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PhotoFileData } from './photo-file-data';
import { PhotoUri } from './photo-uri';

/**
 * 
 * @export
 * @interface Photo
 */
export interface Photo {
    /**
     * 
     * @type {number}
     * @memberof Photo
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Photo
     */
    'date': string;
    /**
     * 
     * @type {PhotoFileData}
     * @memberof Photo
     */
    'file_data': PhotoFileData;
    /**
     * 
     * @type {number}
     * @memberof Photo
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof Photo
     */
    'service_id': number;
    /**
     * 
     * @type {string}
     * @memberof Photo
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Photo
     */
    'updated_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof Photo
     */
    'deleted': boolean;
    /**
     * 
     * @type {number}
     * @memberof Photo
     */
    'capture_id': number;
    /**
     * 
     * @type {number}
     * @memberof Photo
     */
    'child_id': number;
    /**
     * 
     * @type {string}
     * @memberof Photo
     */
    'type': PhotoTypeEnum;
    /**
     * 
     * @type {PhotoUri}
     * @memberof Photo
     */
    'uri': PhotoUri;
}

export const PhotoTypeEnum = {
    Image: 'image',
    Video: 'video'
} as const;

export type PhotoTypeEnum = typeof PhotoTypeEnum[keyof typeof PhotoTypeEnum];


