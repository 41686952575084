import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { SessionProvider } from 'next-auth/react'
import { useRouter } from 'next/router'
import { Session } from 'next-auth'
import { NextPage } from 'next/types'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { IAlert, SnackbarView } from '../views/utils/snackbarview'
import ApiProviderContext from 'common/context/api-provider-context'
import PushNotificationProvider from 'modules/push-notification/components/push-notification-provider'
import { checkBrowserCompatibility } from 'modules/push-notification/utils/notification-utils'
import eventEmitter from 'common/utils/event-emitter'
import { CurrentUserRoleContext } from 'common/context/current-user-role-context'
import 'modules/@plate/styles/globals.css'
import ImageKitContext from 'common/context/image-kit-context'
import { themes } from 'utils/themes'

type NextAuthSession = { session: Session }
export type NextPageWithLayout = NextPage<NextAuthSession> & {
  getLayout?: (page: ReactElement) => ReactNode
}
type AppPropsWithLayout = AppProps<NextAuthSession> & {
  Component: NextPageWithLayout
}

const TheApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const { query } = useRouter()
  const { srvid } = query
  const [alert, setAlert] = useState<IAlert>({} as IAlert)
  const [isBrowserSupportsPushNotification, setIsBrowserSupportsPushNotification] = useState(false)

  useEffect(() => {
    eventEmitter.addListener('onAlert', (alt: IAlert) => setAlert({ ...alt, open: true }))
    pushNotificationValidation()
    return () => eventEmitter.removeAllListeners('onAlert')
  }, [])

  const pushNotificationValidation = () => {
    if (checkBrowserCompatibility()) {
      setIsBrowserSupportsPushNotification(true)
    }
  }

  const theme = useMemo(() => {
    const srvId = srvid as string as keyof typeof themes
    if (srvId in themes) {
      return themes[srvId]
    }
    return themes.default
  }, [srvid])
    

  return (
    <>
      <Head>
        <title>Parent Portal - SmartCentral</title>
        <meta name='parent' content='parent' />
        <link rel='icon' type='images/png' href='/favicon.png' />
      </Head>

      <ThemeProvider theme={theme}>
        <CssBaseline />

        <SnackbarView
          open={alert.open}
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert({ ...alert, open: false, message: '' })}
        />

        <SessionProvider session={pageProps.session} refetchInterval={1800}>
          <ApiProviderContext>
            <CurrentUserRoleContext>
              <ImageKitContext>
                {isBrowserSupportsPushNotification && <PushNotificationProvider />}

                {Component.getLayout ? Component.getLayout(<Component {...pageProps} />) : <Component {...pageProps} />}
              </ImageKitContext>
            </CurrentUserRoleContext>
          </ApiProviderContext>
        </SessionProvider>
      </ThemeProvider>
    </>
  )
}

export default TheApp
