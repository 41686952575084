import { useMemo } from 'react'
import { useQueries, useQuery } from '@tanstack/react-query'
import { useGqlController } from 'common/hooks/use-gql-controller'
import { emitError } from 'common/utils/event-emitter'
import { getSdk } from 'generated/graphql/schema'
import { AccountApiFactory, UserApiFactory } from 'generated/restapi'
import { ClientError } from 'graphql-request'
import { useRestController } from 'common/hooks/use-rest-controller'
import { isAxiosError } from 'axios'
import { toSCId } from 'views/utils/smart-central'

export type TCurrentUserRole = {
  role: string | 'p1'
  roleType: string
  childId: number
  childName: string
  childParent1Id: number
  childParent1Name: string
  obsPlatformAuthorised: boolean
}

export const useCurrentUserRole = () => {
  const { config, query: queryApi, isReady: isReadyApi } = useRestController()
  const { gqlClient, query: queryGql } = useGqlController()

  const { getUserEnrolments } = UserApiFactory(config)
  const { getUserInfo } = AccountApiFactory(config)
  const { getChildrenParentList } = getSdk(gqlClient)

  const { data: userInfo, isLoading: isUserInfoLoading } = useQuery(
    ['fetch-user-info'],
    () => getUserInfo(),
    {
      enabled: isReadyApi && !!queryApi?.srvid,
      onError: (err) => {
        let errMessage = err
        if (isAxiosError(err) && err?.response) {
          errMessage = err?.response?.statusText ?? ''
        }
        emitError(`Error fetching user info. ${errMessage}`)
      },
    }
  )

  const { data: userEnrolments, isLoading: isUserEnrolmentsLoading } = useQuery(
    ['fetch-enrolments'],
    () => getUserEnrolments(parseInt(queryApi.srvid as string)),
    {
      enabled: isReadyApi && !!queryApi?.srvid,
      onError: () => {
        emitError(`Error fetching user enrolments.`)
      },
    }
  )

  const enrolmentIds = useMemo(() => {
    return (
      userEnrolments?.data?.enrolments?.map((enrolment) => {
        return toSCId(enrolment?.e_id?.toString())
      }) ?? []
    )
  }, [userEnrolments])

  const results = useQueries({
    queries: enrolmentIds.map((enrolmentId) => ({
      queryKey: ['fetch-children-parent-list', enrolmentId],
      enabled: enrolmentIds.length > 0,
      queryFn: () =>
        getChildrenParentList({ srvId: queryGql?.srvid as string, enrolmentId }),
      onError: (err: ClientError) => {
        let errMessage = err?.response?.error ?? ''
        emitError(
          `Error fetching Children Parent List Enrolments. ${errMessage}`
        )
      },
    })),
  })

  const currentUserRoles: TCurrentUserRole[] | undefined = useMemo(() => {
    return userEnrolments?.data?.enrolments?.map((enrolment) => {
      const found = results?.find(
        ({ data }) =>
          data?.service?.enrolments?.at(0)?.id ===
          toSCId(enrolment?.e_id.toString())
      )

      const contact = found?.data?.service?.enrolments
        ?.at(0)
        ?.child?.contacts?.find(({ email }) => email === userInfo?.data?.email)

      return {
        role: enrolment?.assoc,
        roleType: contact?.role ?? 'Contact',
        childId: enrolment?.c_id,
        childName: enrolment?.c_name,
        childParent1Id: enrolment?.p_id,
        childParent1Name: enrolment?.p_name,
        obsPlatformAuthorised: contact?.obsPlatformAuthorised ?? false,
      }
    })
  }, [results, userInfo, userEnrolments])

  return {
    currentUserRoles,
    childrenParentDetail: results,
    currentUserInfo: userInfo?.data,
  }
}

export type TUseCurrentUserRole = ReturnType<typeof useCurrentUserRole>
